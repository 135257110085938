<template>
  <div>
    <b-form @submit.prevent="addPermission">
      <b-form-group label="Scope" label-for="scope-input">
        <b-form-input
            id="scope-input"
            v-model="$v.permission.scope.$model"
            type="text"
            required
            placeholder="Renseignez un scope"
            :state="$v.permission.scope.$dirty ? !$v.permission.scope.$invalid : null"
        />
        <b-form-invalid-feedback :state="!$v.permission.scope.$invalid">
          <span v-if="!$v.permission.scope.required">Vous devez renseigner ce champ.</span>
          <span v-else-if="!$v.permission.scope.name">Ce champ ne peut contenir que des caractères alphabétiques.</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Ressource" label-for="resource-input">
        <b-form-input
            id="resource-input"
            v-model="$v.permission.resource.$model"
            type="text"
            required
            placeholder="Renseignez une ressource"
            :state="$v.permission.resource.$dirty ? !$v.permission.resource.$invalid : null"
        />
        <b-form-invalid-feedback :state="!$v.permission.resource.$invalid">
          <span v-if="!$v.permission.resource.required">Vous devez renseigner ce champ.</span>
          <span v-else-if="!$v.permission.resource.name">
            Ce champ ne peut contenir que des caractères alphabétiques.
          </span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Opération" label-for="operation-input">
        <b-form-input
            id="operation-input"
            v-model="$v.permission.operation.$model"
            type="text"
            required
            placeholder="Renseignez une opération"
            :state="$v.permission.operation.$dirty ? !$v.permission.operation.$invalid : null"
        />
        <b-form-invalid-feedback :state="!$v.permission.operation.$invalid">
          <span v-if="!$v.permission.operation.required">Vous devez renseigner ce champ.</span>
          <span v-else-if="!$v.permission.operation.name">
            Ce champ ne peut contenir que des caractères alphabétiques.
          </span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-button type="submit" variant="primary" :disabled="$v.$invalid">
        Envoyer
      </b-button>
    </b-form>
  </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import {name} from "@/validators";

export default {
  data() {
    return {
      permission: {
        scope: "",
        resource: "",
        operation: "",
      },
    };
  },
  validations: {
    permission: {
      scope: {required, name},
      resource: {required, name},
      operation: {required, name},
    },
  },
  methods: {
    async addPermission() {
      try {
        await this.$store.dispatch(
            "permissions/addPermission",
            this.permission
        );
      } catch (e) {
        await this.$swal.fire({
          icon: "error",
          title: "Une erreur est survenue lors de la création de la permission",
          text: e.message,
        });
        return;
      }

      await this.$swal.fire({
        icon: "success",
        title: "Permission créée",
      });
      await this.$router.push({name: "permissions"});
    },
  },
};
</script>
