<template>
  <AddPermissionForm/>
</template>

<script>
import AddPermissionForm from "@/components/permissions/AddPermissionForm";

export default {
  components: {
    AddPermissionForm,
  },
};
</script>